import { AddCircleOutline, Delete } from "@mui/icons-material";
import { Box, Typography, Link, MenuItem, FormControl, IconButton, alpha } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { useEffect, useState } from "react";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import usePickup from "hooks/pickups/usePickup";
import theme from "lib/theme";
import { PickupEstimatedItem } from "types/pickupEstimatedItem";

interface PickupEstimatedItemsProps {
  setItemsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function PickupEstimatedItems({ setItemsCompleted }: PickupEstimatedItemsProps) {
  let [items, setItems] = useState<PickupEstimatedItem[]>([]);
  const { updatePickup } = useUpdatePickup();
  const { pickup } = usePickup();

  const handleQuantityChange = (event: any, index: number) => {
    let newItems = items;

    newItems = items.map((obj) => {
      if (obj === items[index]) {
        return { name: items[index].name, quantity: event.target.value };
      }
      return obj;
    });

    setItems(newItems);
  };

  const handleNameChange = (event: any, index: number) => {
    let newItems = items;

    newItems = items.map((obj) => {
      if (obj === items[index]) {
        return { name: event.target.value, quantity: items[index].quantity };
      }
      return obj;
    });

    setItems(newItems);
  };

  const handleDelete = (index: number) => {
    let newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  useEffect(() => {
    if (pickup) {
      const currentItems = pickup.pickupEstimatedItems.map((item) => {
        return { name: item.name, quantity: item.quantity };
      });

      setItems(currentItems);
    }
  }, [pickup]);

  useEffect(() => {
    updatePickup({ estimatedItems: items });
  }, [items]);

  useEffect(() => {
    if (items.length > 0) {
      setItemsCompleted(true);
    } else {
      setItemsCompleted(false);
    }
  }, [items]);

  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        3. What’s in your pickup?
      </Typography>
      <Typography mt={0.5} variant="body2">
        Let’s breakdown what’s in your pickup. If you’re not exactly sure, take your best guess.
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 40px",
            gap: "12px",
            [theme.breakpoints.down("sm")]: { display: "none" },
          }}
        >
          <Typography fontWeight={600}>What are we picking up?</Typography>
          <Typography fontWeight={600}>Quantity of devices</Typography>
        </Box>

        {items.map((item, i) => {
          return (
            <Box
              key={i}
              sx={{
                mt: "12px",
                display: "grid",
                gridTemplateColumns: "1fr 40px",
                gap: "12px",
                [theme.breakpoints.down("sm")]: { mt: "24px" },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  gap: "12px",
                  [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
                }}
              >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink htmlFor="picking-ups" sx={{ [theme.breakpoints.up("sm")]: { display: "none" } }}>
                    What are we picking up?
                  </InputLabel>
                  <AppSelect
                    id="picking-ups"
                    onChange={(e) => {
                      handleNameChange(e, i);
                    }}
                    value={item.name}
                  >
                    <MenuItem value={"Apple Laptops"}>Apple Laptops</MenuItem>
                    <MenuItem value={"PC Laptops"}>PC Laptops</MenuItem>
                    <MenuItem value={"Laptops"}>Laptops Generic</MenuItem>
                    <MenuItem value={"Desktops"}>Desktops</MenuItem>
                    <MenuItem value={"Chromebooks"}>Chromebooks</MenuItem>
                    <MenuItem value={"Monitors"}>Monitors</MenuItem>
                    <MenuItem value={"Tablets"}>Tablets</MenuItem>
                    <MenuItem value={"Televisions"}>Televisions</MenuItem>
                    <MenuItem value={"Servers"}>Servers</MenuItem>
                    <MenuItem value={"Printers"}>Printers</MenuItem>
                    <MenuItem value={"Small Box"}>
                      Small Box{" "}
                      <Typography color="text.secondary" pl={0.5}>
                        {" "}
                        - 15" L x 12" W x 10" H (in)
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Large Box"}>
                      Large Box
                      <Typography color="text.secondary" pl={0.5}>
                        - 48" L x 40" W x 36" H (in)
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Commercial Bin"}>
                      Commercial Bin
                      <Typography color="gray" pl={0.5}>
                        - 48" L x 24" W x 28" H (in)
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Office Phones"}>Office Phones</MenuItem>
                  </AppSelect>
                </FormControl>

                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="pickups-quantity"
                    sx={{ [theme.breakpoints.up("sm")]: { display: "none" } }}
                  >
                    Quantity of devices
                  </InputLabel>
                  <InputBase
                    id="pickups-quantity"
                    type="number"
                    value={item.quantity}
                    onChange={(e) => {
                      handleQuantityChange(e, i);
                    }}
                  />
                </FormControl>
              </Box>

              <IconButton
                onClick={() => {
                  handleDelete(i);
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      </Box>

      <Link
        component="button"
        onClick={() => {
          setItems([...items, { name: "Apple Laptops", quantity: 1 }]);
        }}
        sx={{
          mt: 1,
          display: "inline-flex",
          alignItems: "center",
          gap: 1,
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "16px",
          fontFamily: "Catamaran",
          ":hover": {
            textDecoration: "underline",
          },
          [theme.breakpoints.down("sm")]: { mt: 2 },
        }}
      >
        <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
        Add items to the pickup
      </Link>

      <Box mt={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}></Box>
    </Box>
  );
}
